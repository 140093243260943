<template>
    <div>
        <app-layout>
            <Header :title="$t('midterm_and_final_data_collection')"
                    @filter-div-status="datatable.filterStatus=$event"
                    :is-columns="true"
                    :is-new-button="true"
                    @new-button-click="createForm"
            >
                <template v-slot:columns>
                    <div class="mb-1" v-for="(column,i) in datatable.columns" :key="i">
                        <b-form-checkbox
                            :id="'checkbox-'+i"
                            :name="'checkbox-'+i"
                            :value="false"
                            :unchecked-value="true"
                            v-model="column.hidden"
                            v-if="column.field!='buttons'"
                            :is-new-button="true"
                            @new-button-click="createForm"
                        >
                            {{ column.label }}
                        </b-form-checkbox>
                    </div>
                </template>
            </Header>
            <HeaderMobile :title="$t('midterm_and_final_data_collection')"
                          @filter-div-status="datatable.filterStatus=$event"
                          :is-columns="true">
                <template v-slot:columns>
                    <div class="mb-1" v-for="(column,i) in datatable.columns" :key="i">
                        <b-form-checkbox
                            :id="'checkbox-'+i"
                            :name="'checkbox-'+i"
                            :value="false"
                            :unchecked-value="true"
                            v-model="column.hidden"
                            v-if="column.field!='buttons'"
                        >
                            {{ column.label }}
                        </b-form-checkbox>
                    </div>
                </template>
            </HeaderMobile>
            <div>
                <datatable-filter @filter="filter"
                                  @filterClear="filterClear"
                                  :export-excel="true"
                                  @exportExcel="downloadAsExcel">

                    <ValidationObserver ref="datafilterValid">
                        <b-tabs content-class="py-5 position-relative" class="line-tabs" v-model="tabIndex">
                            <div class="tabs-bottom-line"></div>
                            <b-tab active ref="tab1">
                                <template #title>
                                    <span v-html="computeTab1Name"></span>
                                </template>
                                <b-row>
                                    <b-col sm="4">
                                        <Validation-provider name="filter.academic_year" rules="required"
                                                             v-slot="{valid,errors}">
                                            <b-form-group :label="$t('academic_year')">
                                                <academic-years-selectbox
                                                    v-model="datatable.queryParams.filter.academic_year"
                                                    :validate-error="errors[0]"/>
                                            </b-form-group>
                                        </Validation-provider>
                                        <b-form-group :label="$t('faculty')">
                                            <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"/>
                                        </b-form-group>

                                        <b-form-group :label="$t('course_code')">
                                            <b-form-input v-model="datatable.queryParams.filter.course_code"/>
                                        </b-form-group>

                                        <b-form-group :label="$t('teaching_staff')">
                                            <staff-auto-complete v-model="datatable.queryParams.filter.teaching_staff"/>
                                        </b-form-group>
                                    </b-col>
                                    <b-col sm="4">
                                        <Validation-provider name="filter.semester" rules="required"
                                                             v-slot="{valid,errors}">
                                            <b-form-group :label="$t('semester')">
                                                <semester-type-selectbox class="filter_date"
                                                                         v-model="datatable.queryParams.filter.semester"
                                                                         :validate-error="errors[0]"/>
                                            </b-form-group>
                                        </Validation-provider>
                                        <b-form-group :label="$t('department')">
                                            <department-selectbox
                                                v-model="datatable.queryParams.filter.department_code"/>
                                        </b-form-group>
                                        <b-form-group :label="$t('course_name')">
                                            <course-auto-complete class="filter_date"
                                                                  v-model="datatable.queryParams.filter.course_name"/>
                                        </b-form-group>
                                    </b-col>
                                    <b-col sm="4">

                                        <Validation-provider name="filter.exam_type" rules="required"
                                                             v-slot="{valid,errors}">
                                            <b-form-group :label="$t('exam_type')">
                                                <parameter-selectbox
                                                    v-model="datatable.queryParams.filter.exam_type"
                                                    code="exam_schedules_types"
                                                    :validate-error="errors[0]"/>
                                            </b-form-group>
                                        </Validation-provider>
                                        <b-form-group :label="$t('program')">
                                            <program-selectbox class="filter_date"
                                                               :faculty_code="datatable.queryParams.filter.faculty_code"
                                                               :department_code="datatable.queryParams.filter.department_code"
                                                               v-model="datatable.queryParams.filter.program"/>
                                        </b-form-group>
                                        <b-form-group :label="$t('section')">
                                            <b-form-input v-model="datatable.queryParams.filter.section"/>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab>
                                <template #title>
                                    <span v-html="computeTab2Name"></span>
                                </template>
                                <b-row>
                                    <b-col sm="4">
                                        <b-form-group :label="$t('course') + ' ' + $t('day')">
                                            <b-form-select v-model="datatable.queryParams.filter.course_day"
                                                           :options="getDaysObject()"/>
                                        </b-form-group>
                                        <b-form-group :label="$t('exam_date')">
                                            <select-date v-model="datatable.queryParams.filter.exam_date"/>
                                        </b-form-group>
                                    </b-col>
                                    <b-col sm="4">
                                        <b-form-group :label="$t('course') + ' ' + $t('start_time')">
                                            <time-picker class="filter_date"
                                                         v-model="datatable.queryParams.filter.course_start_time"
                                                         :is-range="false"/>
                                        </b-form-group>

                                        <b-form-group :label="$t('exam') + ' ' +$t('start_time')">
                                            <time-picker class="filter_date"
                                                         v-model="datatable.queryParams.filter.exam_start_time"
                                                         :is-range="false"/>
                                        </b-form-group>

                                    </b-col>
                                    <b-col sm="4">
                                        <b-form-group :label="$t('course') + ' ' +$t('end_time')">
                                            <time-picker v-model="datatable.queryParams.filter.course_end_time"
                                                         :is-range="false"/>
                                        </b-form-group>
                                        <b-form-group :label="$t('exam') + ' ' + $t('end_time')">
                                            <time-picker v-model="datatable.queryParams.filter.exam_end_time"
                                                         :is-range="false"/>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-tab>
                        </b-tabs>
                    </ValidationObserver>
                </datatable-filter>
                <datatable
                    @on-page-change="onPageChange"
                    @on-per-page-change="onPerPageChange"
                    :rows="datatable.rows"
                    :columns="datatable.columns"
                    :query-params="datatable.queryParams"
                    v-show="datatable.showTable"
                    :is-loading="datatable.isLoading"
                ></datatable>
                <CommonModal ref="formModal" size="xl" :onHideOnlyX="true"
                             @bHideModalHeaderClose="whenCloseModal">
                    <template v-slot:CommonModalTitle>
                        {{ formProcess == 'create' ? $t('create') : $t('update') }}
                    </template>
                    <template v-slot:CommonModalContent>
                        <create-form v-if="formProcess == 'create'" @createFormSuccess="whenCloseModal"/>
                        <update-form v-if="formProcess == 'update'" :form="form" @updateFormSuccess="whenCloseModal"/>
                    </template>
                </CommonModal>
            </div>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";
import Header from "@/layouts/AppLayout/Header";

import Datatable from "@/components/datatable/Datatable";
import DatatableFilter from "@/components/datatable/DatatableFilter.vue";

import CommonModal from "@/components/elements/CommonModal";
import {ValidationObserver, ValidationProvider} from "vee-validate";

import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox.vue";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox.vue";
import StaffAutoComplete from "@/components/interactive-fields/StaffAutoComplete.vue";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox.vue";
import CourseAutoComplete from "@/components/interactive-fields/CourseAutoComplete.vue";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox.vue";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox.vue";
import SemesterTypeSelectbox from "@/components/interactive-fields/SemesterTypeSelectbox.vue";
import SelectDate from "@/components/interactive-fields/SelectDate.vue";
import TimePicker from "@/components/elements/TimePicker.vue";

import CreateForm from "./Create.vue";
import UpdateForm from "./Update.vue";

import qs from "qs";

import ExamRequest from "@/services/ExamRequest";


export default {
    components: {
        SemesterTypeSelectbox,
        Header,
        HeaderMobile,
        AppLayout,

        CreateForm,
        UpdateForm,

        SelectDate,
        DepartmentSelectbox,
        CourseAutoComplete,
        ProgramSelectbox,
        StaffAutoComplete,
        FacultySelectbox,
        AcademicYearsSelectbox,
        ParameterSelectbox,
        TimePicker,


        CommonModal,
        ValidationProvider,
        ValidationObserver,

        Datatable,
        DatatableFilter,

    },
    data() {
        return {
            datatable: {
                rows: [],
                showTable: true,
                isLoading: false,
                filterStatus: true,
                total: 0,
                queryParams: {
                    filter: {
                        academic_year: null,
                        faculty_code: null,
                        course_code: null,
                        teaching_staff: null,
                        semester: null,
                        program_code: null,
                        course_name: null,
                        course_day: null,
                        exam_type: null,
                        department_code: null,
                        section: null,
                        exam_date: null,
                        course_start_time: null,
                        course_end_time: null,
                        exam_start_time: null,
                        exam_end_time: null
                    },
                    limit: 20,
                    page: 1
                },
                columns: [
                    {
                        label: "",
                        html: true,
                        field: "buttons",
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('edit'),
                                class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
                                permission: 'faculty_update',
                                callback: (row) => {
                                    this.updateForm(row)
                                }
                            },
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
                                permission: 'faculty_delete',
                                callback: ({id}) => {
                                    this.formDelete(id)
                                }
                            }
                        ]
                    },
                    {
                        field: 'section_id',
                        label: this.toUpperCase("section_id"),
                        hidden: true,
                        sortable: false
                    },
                    {
                        field: ("course_name"),
                        label: this.toUpperCase("course_name"),
                        hidden: false,
                        sortable: false
                    },
                    {
                        field: ("faculty_name"),
                        label: this.toUpperCase("faculty"),
                        hidden: false,
                        sortable: false
                    },
                    {
                        field: 'is_exam_time_lesson',
                        label: this.toUpperCase("is_exam_time_lesson"),
                        hidden: false,
                        formatFn: (value) => {
                            return value ? this.$t('yes') : this.$t('no')
                        },
                        sortable: false
                    },
                    {
                        field: 'is_additional_classroom',
                        label: this.toUpperCase("is_additional_classroom"),
                        hidden: false,
                        formatFn: (value) => {
                            return value ? this.$t('yes') : this.$t('no')
                        },
                        sortable: false
                    },
                    {
                        field: 'type',
                        label: this.toUpperCase("exam_type"),
                        hidden: false,
                        sortable: false
                    },
                    {
                        field: 'exam_date',
                        label: this.toUpperCase("exam_date"),
                        formatFn: (value) => {
                            return this.toLocaleDate(value)
                        },
                        hidden: false,
                        sortable: false
                    },
                    {
                        field: 'start_time',
                        label: this.toUpperCase("start_time"),
                        hidden: false,
                        sortable: false
                    },
                    {
                        field: 'end_time',
                        label: this.toUpperCase("end_time"),
                        hidden: false,
                        sortable: false
                    },
                    {
                        field: 'explanation',
                        label: this.toUpperCase("explanation"),
                        hidden: false,
                        sortable: false
                    },
                    {
                        field: 'semester',
                        label: this.toUpperCase("semester"),
                        formatFn: (value) => {
                            if (value.academic_year && value.semester_text) {
                                return value.academic_year + '/' + value.semester_text
                            }
                        },
                        hidden: false,
                        sortable: false
                    },
                    {
                        field: 'student_count',
                        label: this.toUpperCase("student_count"),
                        hidden: false,
                        sortable: false
                    },
                ]
            },
            options: [
                {value: true, text: this.$t('yes')},
                {value: false, text: this.$t('no')},
            ],
            form: {},
            formProcess: null,
            tabIndex: 0
        }
    },
    methods: {
        filter() {
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows()
        },
        async getRows() {
            const isValid = await this.$refs.datafilterValid.validate();
            this.redirectToError();
            if (isValid) {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                const config = {
                    params: {...this.datatable.queryParams},
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }

                ExamRequest.getAll(config).then((res) => {
                    this.datatable.rows = res.data.data;
                    this.datatable.total = res.data.pagination.total;
                }).catch(e => this.showErrors(e)).finally(() => this.datatable.isLoading = false)
            }
        },
        redirectToError() {
            let tab1 = this.$refs.tab1.$el.querySelector('.is-invalid,.box-border-color')
            if (tab1) return this.tabIndex = 0;
        },
        updateForm(exam) {
            this.form = exam;
            this.formProcess = 'update';
            this.showModal();
        },
        createForm() {
            this.formProcess = 'create';
            this.showModal();
        },
        showModal() {
            this.$refs.formModal.$refs.commonModal.show();
        },
        hideModal() {
            this.$refs.formModal.$refs.commonModal.hide();
        },
        whenCloseModal() {
            this.formProcess = null;
            this.formId = null;
            this.hideModal()
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        async downloadAsExcel() {
            const isValid = await this.$refs.datafilterValid.validate();
            if (isValid) {

                const config = {
                    params: {...this.datatable.queryParams},
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }
                ExamRequest.exportExcel(config)
                    .then(res => this.$router.push('/job/logs/detail/' + res.data.data.log_id))
                    .catch(err => this.showErrors(err))
            }
        },
        formDelete(id) {
            ExamRequest.del(id)
                .then(() => this.$toast.success(this.$t('proccess_success')))
                .catch(err => this.showErrors(err))
        },
    },
    computed: {
        computeTab1Name() {
            let count = 0;
            let filters = this.datatable.queryParams.filter;

            if (this.isNotNull(filters.academic_year)) count++;
            if (this.isNotNull(filters.semester)) count++;
            if (this.isNotNull(filters.exam_type)) count++;
            if (this.isNotNull(filters.faculty_code)) count++;
            if (this.isNotNull(filters.course_code)) count++;
            if (this.isNotNull(filters.department_code)) count++;
            if (this.isNotNull(filters.program_code)) count++;
            if (this.isNotNull(filters.course_name)) count++;
            if (this.isNotNull(filters.section)) count++;
            if (this.isNotNull(filters.teaching_staff)) count++;

            if (count) {
                let filterButton = '<span class="badge badge-primary ml-1">' + count + '</span>';
                return this.$t('semester_info') + filterButton
            }
            return this.$t('semester_info');
        },
        computeTab2Name() {
            let count = 0;
            let filters = this.datatable.queryParams.filter;

            if (this.isNotNull(filters.exam_date)) count++;
            if (this.isNotNull(filters.exam_start_time)) count++;
            if (this.isNotNull(filters.exam_end_time)) count++;
            if (this.isNotNull(filters.course_start_time)) count++;
            if (this.isNotNull(filters.course_end_time)) count++;
            if (this.isNotNull(filters.course_day)) count++;

            if (count) {
                let filterButton = '<span class="badge badge-primary ml-1">' + count + '</span>';
                return this.$t('date_info') + filterButton
            }
            return this.$t('date_info');
        }
    }

}
</script>
