import Api from "@/services/Index";

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/sections", config);
};

const options = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/sections/options", config);
};

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/sections/" + id);
};

const store = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/sections", formData);
};

const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put("/sections/" + id, formData);
};

const del = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete("/sections/" + id);
};

const instructorCourses = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/instructor/courses", config);
};

const close = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/sections/close", formData);
};

const students = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/sections/" + id + "/students");
};

const studentsReport = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/sections/" + id + "/students/report");
};

const instructorSections = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/instructor/sections", config);
};

const exportExcel = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    config.responseType = "arraybuffer";
    return Api.get("/sections/excel-export", config);
};

const importSectionAsExcel = (semesterId, data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/sections/import/" + semesterId, data);
};

const importSectionDetailAsExcel = (semesterId, data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/section/details/import" + semesterId, data);
};

export default {
    getAll,
    get,
    store,
    update,
    del,

    instructorCourses,
    close,
    students,
    studentsReport,
    instructorSections,
    exportExcel,
    importSectionAsExcel,
    importSectionDetailAsExcel,
    options,
};
