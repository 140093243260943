import Api from '@/services/Index';

const getAll = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/exams', config);
}

const store = (data)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/exams', data);
}

const show = (exam_id)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/exams/'+exam_id);
}

const put = (id,data)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/exams/'+id,data);
}
const deletes = (id)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/exams/'+id);
}

export default {
    getAll,
    store,
    show,
    put,
    deletes
}
